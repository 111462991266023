// 马来文语言包
export default {
    testData: 'ujian',
    language: 'Bahasa Melayu',
    route: {
        home: 'muka depan',
        temp: 'templat',
        serve: 'kelebihan perkhidmatan',
        ourselves: 'tentang kita'
        }
  }