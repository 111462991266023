import Vue from 'vue'
import App from './App.vue'
import i18n from '@/lang/index'
import router from './router'

import ElementUI from 'element-ui'

import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/public.css' // 基础样式 css
// import '@/styles/response.css' // rem单位 css
import '@/styles/common.css' // 共用样式 css

Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
