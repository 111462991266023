import Vue from 'vue'

import VueI18n from 'vue-i18n' // 引入国际化的包

import Cookie from 'js-cookie' // 引入cookie包

import en from "@/lang/en.js"

import zh_cn from "@/lang/zh_cn.js"

import zh_cf from "@/lang/zh_cf.js"

import tv from "@/lang/tv.js"

import bm from "@/lang/bm.js"


Vue.use(VueI18n) // 全局注册国际化包


export default new VueI18n({
  locale: Cookie.get('language') || 'zh_cn', // 从cookie中获取语言类型 获取不到就是中文
  messages: {
    en: {
      ...en, // 将en.js语言包引入
    },
    zh_cn: {
      ...zh_cn, // 加载zh.js语言包引入
    },
    zh_cf: {
      ...zh_cf, // 加载cf.js语言包引入
    },
    tv: {
      ...tv, // 将tv.js语言包引入
    },
    bm: {
      ...bm, // 将bm.js语言包引入
    },
  }

})

