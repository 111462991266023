import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path:'/',
    name:'homePage',
    component:()=>import('../views/home/homePage.vue'),
  },

  // 开发模板页
  {
    path:'/dev',
    name:'devPage',
    component:()=>import('../views/dev/devPage.vue'),
  },  
]

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

// 导航守卫 
router.beforeEach((to,from,next)=>{

  next()

})

export default router
