// 简体中文繁体语言包
export default {
  testData: '測試',
  language: '簡體中文',
  route: {
    home: '首頁',
    temp: '模板',
    serve: '服務優勢',
    ourselves: '關於我們'
    },
  viewDetails: '查看詳情',
  viewTemp: '查看模板',
  knowDetails: '瞭解詳情',
  GoToTrialPlay: '前往試玩',
  businessRelation: '商務聯繫',
  GoSports: '前往GO體育',
  contactUs: '聯繫我們',
  theme: '主題',
  howToCharge: '請輸入您的問題',
  contactEmail: '聯繫郵箱',
  yourContactEmail: '請輸入您的郵箱',
  problemDescription: '問題描述',
  yourQuestion: '請描述您的問題',
  definiteSendOut: '確定送出',
  homePageLang: {
    productTitle: '綜合生態產品',
    productIntroduce: 'GO包網多年技術沉澱，多款產品共築產品矩陣',
    firmTitle: '合作廠商',
    firmIntroduce: 'GO包網多年技術沉澱，多款產品共築產品矩陣',
    clientTitle: '服務客戶',
    clientIntroduce: '以“客戶成功”爲而核心，真正爲客戶創造價值'
  },
  servePageLang: {
    funDataList: [
      {
        title: '最豐富的三方遊戲'
      },
      {
        title: '支持多國語言'
      },
      {
        title: '全終端平臺系統'
      },
      {
        title: '靈活全面自動代理系統'
      },
      {
        title: '大數據ai分析'
      },
      {
        title: '量身定製專屬設計'
      }
    ],
    advantageDataList: [
      {
        title: '7*24小時監控'
      },
      {
        title: '多點式雲資料'
      },
      {
        title: '可疑風險預警'
      },
      {
        title: '國安級防火牆'
      },
      {
        title: 'ssl加密憑證'
      },
      {
        title: '數據加密備份'
      },
      {
        title: '抗DDoS攻擊'
      },
      {
        title: '全方位平臺報表管理'
      }
    ]
  },
  tempPageLang: {
    AG: 'AG',
    Macau: '澳門',
    physicalEducation: '體育',
    chessAndCards: '棋牌',
    lottery: '彩票',
    esports: '電競',
    catchfish: '捕鱼',
    realPerson: '真人',
    electron: '電子',
    //  子内容
    Kaifa: '凱發',
    J9: 'J9',
    Hutchison: '和記',
    newLisboa: '新葡京',
    GalaxyMacau: '澳門銀河',
    SunCity: '太陽城',
    MGMMirage: '美高梅',
    Venetian: '威尼斯人',
    GoldenSands: '金沙',
    NewCentury: '開元',
    Yunyu: '雲遊',
    TangDynasty: '大唐',
    TianchengLottery: '天成彩票',
    PhoenixLottery: '鳳凰彩票',
    apricot: '杏彩',
    panAsia: '泛亞',
    LightningFire: '雷火',
    OBfishing: 'OB捕鱼',
    BGfishing: 'BG捕鱼',
    skyhunter: '天際獵人',
    FishingKing3D: '捕鱼王3D',
    FishingKing: '捕鱼王',
    FishingPark: '捕魚樂園',
    OBRealPerson: 'OB真人',
    AGRealPerson: 'AG真人',
    BGRealPerson: 'BG真人',
    OBelectron: 'OB電子',
    AGelectron: 'PG電子',
    LGDelectron: 'LGD電子'
  },
  ourselvesPageLang: {
    title: 'GO集團',
    contentText1: 'GO集團全稱偉大在線組，志在打造博彩業頂尖包網團隊。',
    contentText2: `十年經驗淬鍊，亞洲一流的項目方案解決團隊，積極尋求創新技術及理念。
    擁有專業的產品團隊規劃需求，常年全天候不斷探索市場發展脈絡，提供近萬臺棋牌遊戲娛樂、老虎機、體育真人等平臺，並提供線上存取款。
    一鍵操作，提供核心運營數據，多維分析玩家數據、渠道數據、成本數據和收益數據，幫助營銷人員做出正確的決策。`,
    contentText3: `擁有自主產權的防劫持技術(已有專利申請)，一鍵批量審覈，自動結算，活動發佈簡單高效 。
    整合同步賬號和資料傳輸，達到隨時隨地不間斷娛樂的享受概念。
    所有網站軟硬件資源均獨享，且配置多副本，智慧切換，給您一個可靠、穩定的環境。
    頂尖系統，因非凡而與衆不同。
    期待您的垂詢！`,
    title2: '里程碑'
  },
  sonArticleContent: {
      goBao: {
        title: 'GO包網',
        homeText: '我們以技術爲先導，不斷推廣先進適用技術的應用，推動客戶業務成長，實現共創、共營、共贏',
        rowText1: 'GO博包網項目立項於2012年,累計已發展10餘年,累計服務客戶上百+位技術團隊目前已有百位+ 24小時保障項目穩定運行。開發出60+個不同類型模版，滿足市場90%的需求。如您的想法,市面模板無法滿足,我們將會從交互設計,界面設計到功能定製,我們提供全方位的解決方案,包括PC, H5, IOS籤,服務器,域名等一條龍服務。',
        rowText2: '提供中文、英文、越南文等多國語言版本，以及任意語言定製服務；採用純原生技術開發，徹底解決充值資金丟失、流量劫持等問題，保障網站安全；200多種遊戲選擇，無死角安全監控，全方位保駕護航！',
        netPrice: {
          title: '包網價格',
          openingCharge: {
            title: '開版費',
            dataList: [
              {
                content: '專業團隊技術配置指導開站事宜'
              },
              {
                content: '高級UI設計師協助處理頁面展示效果'
              },
              {
                content: '專業技術團隊高效快捷部署開站'
              },
              {
                content: '免費對接三方支付、短信平臺等'
              },
              {
                content: '運維團隊項目部署，域名解析等'
              }
            ]
          },
          BasicCharge: {
            title: '基本收费',
            content1: '最終洽談後如期交付',
            content2: '原生APP、PC端、H5端',
            content3: '（定製化平臺或者需求及時聯繫商務哦）',
          }
        },
      productAdvantage: {
        title: '產品優勢',
        dataList: [
          {
            title: '快速定製高速產品',
            content: '高端設計品牌服務，技術過硬，更是行業新秀。只要你有流量，我們就能帶您變現！'
          },
          {
            title: '優質的產品體驗',
            content: '行業內最極致移動端三端齊全，多端加密告別劫持，全民推廣無憂。'
          },
          {
            title: '運營服務7*24小時',
            content: '7*24小時專業客服，風控團隊財務團隊acb全程對接，分秒必爭。'
          }
        ]
      }
    },
    integrated: {
      title: '綜合色站',
      homeText: '國產電影福利亂色，日韓av高清，國產電影比較色 日韓歐美國產免費成人高清在線AV電影視頻'
    },
    lottory: {
      title: '彩票API',
      homeText: 'GoGame彩票，提供專業彩票遊戲API，位玩家提供最優質的 娛樂與體驗，想成爲最與衆不同的娛樂平臺',
      rowText1: 'GO於業界最齊全綵票遊戲，擁有多個地區的的官方時時彩，低頻彩、PK10、六合彩、快開彩、境外彩票等，直選、單式、混合、冠亞軍…等，即時的開獎結果，查詢、走勢、分析⼀體化服務，絕對滿足各類彩票玩家的喜好。',
      rowText2: '',
      dataList: [
        {
          title: '虛擬幣時彩',
          content: '支持比特幣、以太坊遊戲彩票玩法'
        },
        {
          title: '多元境外彩',
          content: '支持加拿大、香港、臺灣等其不同地區彩種'
        },
        {
          title: '即時彩',
          content: '極速彩種多元'
        }
      ]
    },
    sport: {
      title: '體育直播',
      homeText: '原生通訊軟件開發，提供使用者更好的聊天體驗',
      rowText1: `專門提供足球直播、NBA直播、CBA直播等體育賽事，我們將提供全球最熱門體育賽事足球包含世界盃、五大聯賽、歐冠等至優質直播內容。
      賽事直播間具備完整的直播管理和社交互動功能，包括給主播打算、送禮物，支持直播間發紅包，直播間消息互動支持多種彈幕。
      強大的後臺管理功能可支撐各種運營需求，對賽事直播、主播進行有效管理。`,
      rowText2: '',
      subhead: '直播數據源',
      description: '我們將提供更爲豐富的比賽直播數據源，除了體育賽事外，還提供電子競技更優良的數據源！',
      dataList: [
        {
          title: '英格蘭超級聯賽',
          cont: '通常被稱爲英超，是英格蘭足球最高等級的賽事類別亦是世上最頂級的足球聯賽之一，由英格蘭足球總會於1992年2月20日確立，自1992–93球季面世'
        },
        {
          title: '意大利足球甲組聯賽',
          cont: '意大利足球聯賽系統的第1級別 ，亦是職業聯賽的最高級別，聯賽系統的最高級別和意大利頂級足球聯賽，由意大利足球總會所管理，意甲職業聯盟營運。'
        },
        {
          title: '法國足球甲級聯賽',
          cont: '法國足球聯賽系統的第1級別，亦是職業聯賽的最高級別，聯賽系統的最高級別和法國頂級足球聯賽，由法國足球協會監管下的法國職業足球聯賽所負責監督、組織及管理。'
        },
        {
          title: '德國足球甲級聯賽',
          cont: '德國足球最高等級的賽事類別，由德國足球協會於1962年7月28日在多特蒙德確立，自1963-64賽季面世。'
        },
        {
          title: '西班牙足球甲級聯賽',
          cont: '西班牙足球聯賽系統的第1級別，亦是職業聯賽的最高級別，聯賽系統的最高級別和西班牙頂級足球聯賽。目前西甲有 20 支球隊，最後三名會降至西班牙足球乙級聯賽。'
        },
        {
          title: 'FIFA世界盃',
          cont: '成立於 1904 年，負責監督比利時、丹麥、法國、德國、荷蘭、西班牙國家協會之間的國際比賽、瑞典和瑞士。總部設在瑞士蘇黎世，其成員現在包括211個國家協會。'
        },
        {
          title: '歐洲冠軍聯賽',
          cont: '歐洲足聯主辦的年度足球比賽，代表歐洲俱樂部足球最高榮譽，組織完善高效，被譽爲全世界最高競技水平的俱樂部盃賽，亦是世界上獎金最高的足球賽事和體育賽事之一。'
        },
        {
          title: '中國足球協會超級聯賽',
          cont: '中國足球協會所主辦的最高等級職業足球聯賽。該聯賽開始於2004年，脫胎自原中國足球甲級A組聯賽。'
        },
        {
          title: 'DOTA2國際賽',
          cont: 'Dota 2開發商威樂公司舉行的電子競技比賽，比賽將由十六個受邀請的隊伍完成。這項賽事在2011年5月25日科隆國際遊戲展上首次進行，也是到今爲止獎金額度最高的比賽。'
        },
        {
          title: '英雄聯盟全球總決賽',
          cont: '2017年開始，英雄聯盟全球總決賽擴大規模。外卡巡迴賽被取消，改爲由擴大的英雄聯盟全球總決賽直接包含原外卡巡迴賽的功能。'
        },
        {
          title: '穿越火線職業聯賽',
          cont: '穿越火線在中國大陸地區的頂級賽事。由騰訊遊戲主辦，量子體育承辦。自2012年首屆聯賽舉辦以來，CFPL已經創辦了15個賽季。'
        },
        {
          title: 'CS:GO超級聯賽',
          cont: 'CS:GO超級聯賽。第一屆於2016年3月分開啓。是由國家體育總局體育信息中心主辦，上海華奧電競信息科技有限公司承辦。'
        }
      ]
    },
    video: {
      title: '視訊綜合',
      homeText: '提供業界最專業的美女視訊',
      rowText1: `視訊綜合給玩家帶來前所未有的娛樂體驗，是您提升業務的必備產品。
      爲此GO集團與世界頂端的網絡遊戲軟件供應商一起合作，讓娛樂運營商可在自己的白標網頁添加最新的內容。
      同時我們爲玩家提供不同類型的遊戲體驗，無與倫比的圖形和先進的技術，創造一種身臨其境和互動的經驗來吸引玩家的注意力。
      以高規格打造的國際廳配備最先進的設備以及富麗堂皇的裝潢，爲玩家打造絕佳真人視訊遊戲體驗。
      遊戲選擇多樣化，包括備受歡迎的百家樂丶骰寶丶輪盤等，加上全天侯監控管理，爲玩家提供穩定而流暢的遊戲服務。`,
      rowText2: '',
      dataList: [
        {
          pictitle: '經典百家樂',
          title: '經典百家樂',
          cont: `百家樂是世界上其中一款最受歡迎的遊戲。
          首創六張牌先發百家樂，確保遊戲公平，令玩家對平臺的信心大增。
          另外，我們亦獨家研發特色LED桌臺競咪百家樂、25秒極速百家樂，給予玩家一種置身實地賭場的真實快感。`
        },
        {
          pictitle: '競咪百家樂',
          title: '競咪百家樂',
          cont: `競咪互動百家樂強勢推出手動切牌功能，當局額度最大的玩家，即可享此限時20秒的優越功能。
          透過局部放大的視頻及牌上顯示的虛線以輔助切牌，確保牌局絕對公平公正，同時讓玩家自主性大增，真實感比擬實地賭場。`
        },
        {
          pictitle: '保險百家樂',
          title: '保險百家樂',
          cont: '保險百家樂是近年最受玩家歡迎的一個新功能，顧名思義在遊戲中加入保險元素，除了不影響百家樂遊戲的結果外，同時亦能保障玩家不至於輸掉所有的押注金額。'
        },
        {
          pictitle: '龍寶百家樂',
          title: '龍寶百家樂',
          cont: `龍寶會根據所投注一方勝出對方點數的情況，賠率會有所不同，最高彩金爲30倍，且所贏得彩金不抽水。
          若投注其中一方獲勝，並且符合下列兩種之一即可獲得派彩。`
        },
        {
          pictitle: 'VIP百家樂',
          title: 'VIP百家樂',
          cont: 'VIP包桌後可自行控制遊戲節奏，盡享更換荷官、飛牌及換靴等優越功能，成就更高級及自主的娛樂享受。'
        },
        {
          pictitle: '龍虎',
          title: '龍虎',
          cont: '龍虎 加入真人遊戲平臺後，讓更多玩家於電腦以及任何移動裝置連接我們的娛樂平臺，感受猶如置身現場的享樂新體驗，一試自己的運氣！'
        },
        {
          pictitle: '',
          title: '牛牛',
          cont: `牛牛 又名鬥牛，是一款流行於廣東、廣西、湖南以及江浙一帶的傳統棋牌遊戲。
          其中一家爲莊家，其餘爲閒家，發完牌後馬上開牌比牌，所有閒家與莊家一一進行比較確定輸贏。
          遊戲節奏緊湊刺激，規則簡單。`
        },
        {
          pictitle: '21點',
          title: '21點',
          cont: `21點源於法國，只要玩家的撲克牌擁有最高21點數並大於荷官點數便獲勝。
          牌局的最終目的，是要玩家手上擁有的牌總點數達到21點（或最接近21點），然後和荷官比大小。`
        },
        {
          pictitle: '炸金花',
          title: '炸金花',
          cont: `炸金花（詐金花）又稱贏三張，是在全國廣泛流傳的一種民間多人紙牌遊戲。
          玩家以手中的三張牌比大小，具有獨特的比牌規則。`
        },
        {
          pictitle: '輪盤',
          title: '輪盤',
          cont: `最逼真的真人輪盤遊戲。
          透過實時傳遞的現場畫面，加上目不暇給的輪盤丶荷官熟練而專業的技巧，都爲玩家締造一個公平公正的博彩遊戲。
          本遊戲採用國際標準的單零輪盤。 
          玩家可以買單一數字或賭桌上的數字組合`
        },
        {
          pictitle: '骰寶',
          title: '骰寶',
          cont: `真人骰寶遊戲開拓出嶄新領域，付予玩家前所未有的全新感受，令人樂而忘返。
          也叫賭大小，以猜測骰子開出的點數或是點數總合的博彩。
          多元化的投注種類及吸引的賠率，極之受玩家歡迎。`
        },
        {
          pictitle: '多臺遊戲',
          title: '多臺遊戲',
          cont: `在這裏，你可按自己喜好選擇不同類型的遊戲並同時進行下注。
          你只須按‘選擇桌臺’按鈕再安排桌臺顯示的位置便可。`
        }
      ]
    },
    CDN: {
      title: 'CDN',
      homeText: '透過互聯網互相連接的電腦網絡系統，利用最靠近每位用戶的 服務器，更快、更可靠地將圖片、視頻、應用程序及其他文件 發送給用戶',
      rowText1: `作爲有着領先卓越技術的CDN供應商，GO包網早在2012年開始就構建了下一代內容交付架構。
      我們的使命是開發和設計可供儘可能多的人訪問的內容交付解決方案。 
      不僅僅是性能、安全方面的CDN的基礎服務，簡單易用和高性價比對於方能CDN的用戶亦是非常重要。
      我們的CDN只需點擊幾下即可讓您入門，同時還提供多種配置選項，簡單易用可以讓您更專注於核心業務，也正是這樣的簡單易用性讓我們的產品具有極高的性價比，在競爭對手中脫穎而出。作爲有着領先卓越技術的CDN供應商，GO包網早在2012年開始就構建了下一代內容交付架構。
      我們的使命是開發和設計可供儘可能多的人訪問的內容交付解決方案。 
      不僅僅是性能、安全方面的CDN的基礎服務，簡單易用和高性價比對於方能CDN的用戶亦是非常重要。
      我們的CDN只需點擊幾下即可讓您入門，同時還提供多種配置選項，簡單易用可以讓您更專注於核心業務，也正是這樣的簡單易用性讓我們的產品具有極高的性價比，在競爭對手中脫穎而出。`,
      rowText2: '',
      dataList: [
        {
          title: 'CDN內容分發',
          contentText: 'CDN在五大洲擁有三十多個數據中心，其中國內我們擁有三線高防服務器機房，可以緩存您網站中的靜態內容，可自動將用戶指向最快的節點。',
        },
        {
          title: '網站保護',
          contentText: '高防CDN網站保護可使您的網站訪問質量得到保護，可用性大幅提高，在面對DDoS、CC、劫持等常規攻擊的時候，無需擔心。',
        },
        {
          title: 'Web應用防火牆',
          contentText: '提供精確的網站指紋識別技術和高效的處理引擎，結合實時更新的高防防禦策略，覆蓋OWASP主流的攻擊類型，保護您的網站免受黑客攻擊。',
        },
        {
          title: '訪問優化',
          contentText: 'CDN包含一套Web優化功能，可將您的網頁壓縮50%以上，優化包括最新的Web標準，結合我們的高防CDN，可大幅優化用戶訪問速度。',
        },
        {
          title: '無上限的DDos防禦',
          contentText: '無論攻擊的大小，類型或持續時間如何，方能都會爲您在節點邊緣抵擋任何DDOS攻擊，並且沒有上限。',
        },
        {
          title: '人機交互',
          contentText: '具備大數據智能業務防禦能力，支持多維度自定義精準訪問控制、配合人機識別和頻率控制等對抗手段，可智能高效的過濾垃圾訪問，有效防禦 CC 攻擊。',
        }
      ]
    },
    etp: {
      title: '產品孵化',
      homeText: '一個產品從0到1的啓動與實施',
      rowText1: '集團創業中心爲中小型創業者成立的專業服務機構，我們免費提供指導技術業務、管理諮詢等幫幫方案。',
      rowText2: '',
      dataList: [
        {
        title: '產品分析',
        contentText: `包含需求分析、市場分析、競爭對手分析、法規政策分析。
        擬定初步設計方案並提出初步開發週期計劃。`,
        },
        {
        title: '產品評估',
        contentText: '業內部技術負責人對產品申 請進行審覈評估，進行項目初步投資分析。',
        },
        {
        title: '項目經理',
        contentText: '據業界豐富經驗的項目經理，予以產品孵化過程的專業管理，將產品價值最大化。',
        },
        {
        title: '專業開發團隊',
        contentText: '擁有產業豐富開發經驗團隊，以最短時間開發，達到最高品質的產品。',
        },
      ]
    },
    software: {
      title: '通訊軟件',
      homeText: '原生通訊軟件開發，提供使用者更好的聊天體驗',
      rowText1: `客戶端是自由及開放源代碼軟件，但服務端是專有軟件。
      用戶可以相互交換加密與自毀消息，發送照片、視頻等所有類型文件。
      官方提供手機版、桌面版和網頁版等多種平臺客戶端`,
      rowText2: '',
      dataList: [
        {
            title: '貼上',
            text: '即時動態記事貼文，支援多種文件、照片或文字！',
        },
        {
            title: '閱後即焚',
            text: '提前安排寄送時間與內容，定時自動發送',
        },
        {
            title: '快速切換聊天室',
            text: '快速切換聊天室',
        },
        {
            title: '已讀資訊',
            text: '觀看訊息讀取狀態',
        },
        {
            title: '紅包',
            text: '羣發、私聊紅包發送',
        },
        {
            title: '自動語音播放',
            text: '手機靠近即可聽取語音訊息',
        },
      ]
    },
    waiter: {
      title: 'GO客服',
      homeText: '網絡安全就是爲了防範這種信息盜竊和商業競爭攻擊所採取的措施',
      rowText1: `GO客服系統是集軟件、推廣、營銷、管理爲一體的高效客服系統。 
      即時聊天快速接入，訪客詳細信息，智能機器人服務，多客服多平臺對話分配，數據分析保存歷史會話等全面穩定的功能。 
      系統專爲海外客戶優化，Tpds級防護，AI智能防禦攻擊。`,
      rowText2: '',
      dataList: [
        {
            title: '整合簡單 快速部署',
            text: '您只需要簡單的幾步可以使您的網站擁有客服功能，爲您的客戶提供最優服務。'
        },
        {
            title: '界面簡潔 操作方便',
            text: 'GO客服系統，界面簡潔，客服和管理員可以快速學習和使用，系統流程高效、快速穩定。',
        },
        {
            title: '安全高效 私有化部署',
            text: 'GO系統，全面支持私有化部署，幫助你的企業可以更加安全高效的進行客服作業。',
        },
        {
            title: '強大後臺 高效管理',
            text: 'GO系統整合信息管理和客服管理，提高團隊效率，工作質量，同時兼顧客戶體驗充分了解您的客戶。',
        },
        {
            title: '服務保障 強大後盾',
            text: '客服系統爲您提供優質的售後服務，精英團隊爲您解決使用中的一切困難，保障您使用的舒適穩定。',
        },
        {
            title: '多域名多平臺',
            text: '全面支持多域名多平臺簡單部署，讓您高效掌握全局，輕鬆管理多個客服。',
        },
    ]
    },
  }
}